/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    a: "a",
    em: "em",
    blockquote: "blockquote",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 285px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 105.0632911392405%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMEAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAfZnxmw3IpdkhLA//8QAGhAAAwEAAwAAAAAAAAAAAAAAAAECEQMQEv/aAAgBAQABBQKnKPSKrC5ZlljWj411/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHRAAAgAHAQAAAAAAAAAAAAAAAAEQESAhIlFhcf/aAAgBAQAGPwLcMVYczgvKP//EABwQAQACAwADAAAAAAAAAAAAAAEAESExQVFhkf/aAAgBAQABPyEW6F6Zmtz8isAaXCCVA78zUIC7UwQGuiAaYiuBAqf/2gAMAwEAAgADAAAAEDTHwf/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB0QAQEBAAICAwAAAAAAAAAAAAERACExQfBRYYH/2gAIAQEAAT8QakB5Au8tCh6MuCQV3KWSItPxPZkKYAQjfvdrYHIPnc4Zb3o4aLAwAC/rd//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"guillotina papel\"\n        title=\"guillotina papel\"\n        src=\"/static/584d97025884922f5d6e164166a9275a/6c669/guillotina-papel-1-285x300.jpg\"\n        srcset=\"/static/584d97025884922f5d6e164166a9275a/ff44c/guillotina-papel-1-285x300.jpg 158w,\n/static/584d97025884922f5d6e164166a9275a/6c669/guillotina-papel-1-285x300.jpg 285w\"\n        sizes=\"(max-width: 285px) 100vw, 285px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "La política es como los culos, cada cual tiene el suyo. Por eso precisamente estoy en contra de los partidismos, no creo que se pueda condensar en un partido político toda la opinión política de una persona, en cuanto generalizas pierdes los matices. Pero volvamos al tema del post, la siguiente reflexión me ha venido a la cabeza a partir de ", React.createElement(_components.a, {
    href: "https://mierda.tv/2018/08/10/clamav-un-enfoque-apolitico-que-respeta-la-vida-artificial/"
  }, "el post de Clamav de Fanta"), ". Cuando realizamos cualquier pieza de software tomamos una serie de decisiones ", React.createElement(_components.em, null, "por diseño"), ", arbitrarias, porque nos dan la gana, que posiblemente no sean las mismas que haría otra persona. Cuando hacemos esto, estamos implementando política dentro de nuestro software, estamos haciendo un software político en lugar de uno apolítico."), "\n", React.createElement(_components.p, null, "Para esto precisamente apareció la configurabilidad en el software, dejar al administrador del sistema la posibilidad de establecer la política que él desee en cada momento. Incluso correr múltiples instancias del mismo software con distintas políticas o cualquier experimento que se desee."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "La libertad de ejecutar el programa como se desee, con cualquier propósito (libertad 0)."), "\n", React.createElement(_components.li, null, "[…]"), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.gnu.org/philosophy/free-sw.es.html"
  }, "Las 4 libertades esenciales del software libre.")), "\n", React.createElement(_components.p, null, "El usuario como marca la primera libertad del software libre va a ejecutar ese software como desee, o si lo desea no lo ejecutará. Todo software por muy configurable que sea implica algún tipo de política y ética que vas a asumir haciendo correr ese software. Gastar software libre se alinea con una política muy distinta a la de gastar software propietario, puedes ser consciente de ello o no, puede que te importe o no si eres apolítico en esta cuestión, pero de una forma u otra estás participando de una u otra política al igual que cuando votas o dejas de votar a un cierto partido, comes en un Burger King o en el bar de tu barrio, es política de consumo, posiblemente la política que menos efecto tenga, pero no quita para que continúe estando ahí."), "\n", React.createElement(_components.p, null, "Pero una vez decidido a utilizar un software qué mejor que amoldarlo a tus necesidades en todo lo posible, sí, a tu política. Esto es lo que hacen las distribuciones Linux, ", React.createElement(_components.a, {
    href: "http://www.kmkeen.com/maintainers-matter/"
  }, "aplicar una política al software"), ", configurarlo para homogeneizarlo a una serie de políticas preestablecidas. ¡Si una distro es una especie de partido político del software!"), "\n", React.createElement(_components.p, null, "Por lo tanto cuanto más configurable sea tu software a más políticas distintas se podrá aplicar, más apolítico será. Recuerda que un software sólo es una herramienta, al igual que, por ejemplo, lo es la guillotina que encabeza el post (y también otras), su carácter político se lo da el uso que le demos a esa herramienta los usuarios."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
